/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import sortBy from 'lodash/sortBy';
import {
  Table,
  Grid,
  Search,
  Button,
  Pagination,
  Image,
  Checkbox,
  Dropdown,
  Modal,
} from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import { useMediaQuery } from '@react-hook/media-query';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import { asCurrency } from '../../../utils/NumberFormatter';
import { useStateValue } from '../../../core/context/StateProvider';
import { getItemsData, getSearchData } from './ItemsScreenData';
import './ItemsScreen.css';
import ItemsScreenModal from './ItemsScreenModal';
import ItemsScreenUploadModal from './ItemsScreenUploadModal';
import { deleteItems } from '../../../api/ItemAPI';
import { getAllCategoriesOfShop } from '../../../api/CategoryAPI';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';
import newbookingIcon from '../Bookings/icons/newbooking.png';
import uploadIcon from '../Bookings/icons/upload.png';

const ItemsScreen = () => {
  const [{ token, shop, config }, dispatch] = useStateValue();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [isItemsAvailable, setIsItemsAvailable] = useState(true);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState(null);
  const [tableData, setTableData] = useState({
    items: [],
    selectedColumn: null,
    sortDirection: 'descending',
    totalPages: 0,
    currentPage: 1,
    isSearching: false,
    searchTerm: '',
  });
  const [modalOptions, setModalOptions] = useState({ item: null, open: false });
  const [uploadModalOptions, setUploadModalOptions] = useState({ open: null });
  const [searchValue, setSearchValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  const matches = useMediaQuery('only screen and (min-width: 768px)');

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  useEffect(() => {
    if (tableData) {
      if (tableData.items.length === 0 && !tableData.isSearching && isItemsAvailable) {
        getItems(shop._id, 1, 'created', 'descending', null, selectedCategory);
        getDropdownOptions();
      }
    }
    if (tableData == null && !refreshRequired) {
      setErrorWarningModalOptions({ open: true, message: i18n.t('itemsLoadingError') });
      setRefreshRequired(true);
    }
  }, [tableData]);

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (categories != null) {
      const category = categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (categories !== null) {
      const category = categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  const getDropdownOptions = async () => {
    try {
      const res = await getAllCategoriesOfShop(shop._id.toString());
      if (res && res.success && res.categories) {
        if (res.categories.length > 0) {
          const translatedCategories = res.categories.map((category) =>
            getCategoryTranslation(category)
          );
          translatedCategories.sort();
          const options = translatedCategories.map((category) => ({
            key: category.key,
            text: category,
            value: category,
          }));
          setCategories(res.categories);
          setDropdownOptions(options);
        }
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('categoryLoadError'),
        });
      }
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('categoryLoadError'),
      });
      setRefreshRequired(true);
    }
  };

  const getItems = async (shopId, page, entity, direction, selectedColumn, category) => {
    const itemsTableData = await getItemsData(
      shopId,
      page,
      entity,
      direction,
      selectedColumn,
      category,
      token
    );
    if (itemsTableData && itemsTableData.items.length === 0) {
      setIsItemsAvailable(false);
    }
    setTableData(itemsTableData);
  };

  const searchInItems = async (searchTerm, page) => {
    const searchData = await getSearchData(shop._id, searchTerm, page, token);
    setIsSearchLoading(false);
    setTableData(searchData);
  };

  const onCloseModal = async (refreshNeeded) => {
    setUploadModalOptions({ open: false });
    setModalOptions({ item: null, open: false });
    if (refreshNeeded) {
      if (selectedCategory != null) {
        await getItems(
          shop._id,
          tableData && tableData.currentPage !== null ? tableData.currentPage : 1,
          'created',
          'descending',
          null,
          selectedCategory
        );
      } else {
        await getItems(
          shop._id,
          tableData && tableData.currentPage !== null ? tableData.currentPage : 1,
          'created',
          'descending'
        );
        await getDropdownOptions();
      }
      setSearchValue('');
    }
  };

  const handleSort = async (selectedColumn) => {
    if (!tableData.isSearching) {
      if (tableData.selectedColumn !== selectedColumn) {
        await getItems(
          shop._id,
          tableData.currentPage,
          selectedColumn,
          'ascending',
          selectedColumn,
          selectedCategory
        );
        return;
      }
      const sortDirection = tableData.sortDirection === 'ascending' ? 'descending' : 'ascending';
      await getItems(
        shop._id,
        tableData.currentPage,
        selectedColumn,
        sortDirection,
        selectedColumn,
        selectedCategory
      );
    } else {
      const isSearching = tableData.isSearching ? tableData.isSearching : false;
      if (tableData.selectedColumn !== selectedColumn) {
        setTableData({
          items: sortBy(tableData.items, [selectedColumn]),
          selectedColumn,
          sortDirection: 'ascending',
          totalPages: tableData.totalPages,
          currentPage: tableData.currentPage,
          isSearching,
          searchTerm: tableData.searchTerm,
        });
        return;
      }
      setTableData({
        items: tableData.items.slice().reverse(),
        selectedColumn,
        sortDirection: tableData.sortDirection === 'ascending' ? 'descending' : 'ascending',
        totalPages: tableData.totalPages,
        currentPage: tableData.currentPage,
        isSearching,
        searchTerm: tableData.searchTerm,
      });
    }
  };

  const handlePageChange = async (_, { activePage }) => {
    if (tableData.isSearching) {
      await searchInItems(tableData.searchTerm, activePage);
    } else {
      await getItems(shop._id, activePage, 'created', 'descending', null, selectedCategory);
    }
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (searchTerm.length > 3) {
      setIsSearchLoading(true);
      await searchInItems(searchTerm, 1);
    }
    if (searchTerm.length === 0) {
      await getItems(shop._id, 1, 'created', 'descending', null);
    }
  };

  const onDeleteItems = async (itemIds) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('deleteItems'),
        message: i18n.t('deleteItemsWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          const res = await deleteItems(token, itemIds);
          setSelectedForDeletion([]);
          if (res && res.success) {
            await getItems(shop._id, 1, 'created', 'descending', null);
            await getDropdownOptions();
            closeWarningModal();
          } else {
            setErrorWarningModalOptions({
              open: true,
              message: i18n.t('deleteItemsError'),
            });
            closeWarningModal();
          }
        },
        no: true,
        onNo: () => {
          closeWarningModal();
        },
      });
    } catch (error) {
      setErrorWarningModalOptions({
        open: true,
        message: i18n.t('deleteItemsError'),
      });
      closeWarningModal();
    }
  };

  const onSelectForDelete = (index) => {
    const indexOfSelected = selectedForDeletion.findIndex((selected) => selected === index);
    if (indexOfSelected !== -1) {
      const selectedArrayCpy = cloneDeep(selectedForDeletion);
      selectedArrayCpy.splice(indexOfSelected, 1);
      setSelectedForDeletion(selectedArrayCpy);
    } else {
      setSelectedForDeletion([...selectedForDeletion, index]);
    }
  };

  const onDropDownChange = async (option) => {
    const category = categories.find(
      (category) => category.name === getOriginalCategoryFromName(option)
    );
    if (category) {
      setSelectedCategory(category._id);
      await getItems(shop._id, 1, 'created', 'descending', null, category._id);
    } else {
      setSelectedCategory(null);
      await getItems(shop._id, 1, 'created', 'descending');
    }
  };

  const parseItems = (items) =>
    items.map((item, index) => (
      <Fragment key={`${item._id.toString()}frag`}>
        <Table.Row
          key={item._id}
          style={{ backgroundColor: selectedForDeletion.includes(index) ? '#7268cd1f' : '' }}
        >
          {matches ? (
            <>
              <Table.Cell
                key={`${item._id}11`}
                width="1"
                selectable={false}
                verticalAlign="middle"
                textAlign="center"
                onClick={() => onSelectForDelete(index)}
              >
                <Checkbox
                  onChange={() => onSelectForDelete(index)}
                  checked={selectedForDeletion.includes(index)}
                />
              </Table.Cell>
              <Table.Cell
                key={`${item._id}1`}
                collapsing
                // verticalAlign="middle"
                width="2"
                onClick={() => setModalOptions({ item, open: true })}
              >
                <Image
                  src={`${config.cloudinaryUrlPrefix}${item.picture}`}
                  // centered
                  className="item-image"
                />
              </Table.Cell>
            </>
          ) : (
            <></>
          )}
          <Table.Cell
            key={`${item._id}2`}
            collapsing
            onClick={() => setModalOptions({ item, open: true })}
          >
            {item.title}
          </Table.Cell>
          <Table.Cell
            key={`${item._id}3`}
            collapsing
            onClick={() => setModalOptions({ item, open: true })}
          >
            {getCategoryTranslationForName(item.categoryName)}
          </Table.Cell>
          <Table.Cell
            key={`${item._id}4`}
            collapsing
            onClick={() => setModalOptions({ item, open: true })}
          >
            {`${asCurrency(item.price / 100)} €`}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title">{i18n.t('itemsHeading')}</p>
        <p className="cockpit-subtitle">{i18n.t('itemsSubtitle')}</p>
      </div>
      <div className="bookings-items-toolbar">
        <img
          src={uploadIcon}
          alt=""
          style={{ cursor: 'pointer', width: 30, height: 32, marginTop: -6 }}
          onClick={async () => {
            setUploadModalOptions({ open: true });
          }}
        />
        <p style={{ color: '#7268cd', fontWeight: 500, fontSize: 18, marginTop: -4 }}>|</p>
        <img
          src={newbookingIcon}
          alt=""
          style={{ cursor: 'pointer', width: 32, height: 32, marginTop: -6 }}
          onClick={async () => {
            setModalOptions({ open: true, item: null });
          }}
        />
      </div>
      <div
        className="bookings-items-container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className="bookings-header-container">
          <div className="bookings-search">
            <Search
              placeholder={i18n.t('itemsSearch')}
              className="bookings-searchbar"
              onSearchChange={(_, data) => handleSearch(data.value)}
              open={false}
              loading={isSearchLoading}
              value={searchValue}
            />
          </div>
          <div className="bookings-pagination">
            <div className="bookings-pagination-center">
              {selectedForDeletion.length > 0 ? (
                <Button
                  icon="trash"
                  color="teal"
                  style={{ height: 40, width: 40 }}
                  onClick={() => {
                    const itemIds = tableData.items
                      .map((item, index) => {
                        if (selectedForDeletion.includes(index)) {
                          return item._id.toString();
                        }
                        return null;
                      })
                      .filter((item) => item !== null);
                    onDeleteItems(itemIds);
                  }}
                />
              ) : (
                <Pagination
                  prevItem="<"
                  nextItem=">"
                  activePage={tableData ? tableData.currentPage : 1}
                  totalPages={tableData ? Math.ceil(tableData.totalPages) : 0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  boundaryRange={0}
                  onPageChange={(e, props) => handlePageChange(e, props)}
                  pointing
                  secondary
                />
              )}
            </div>
          </div>
          <div className="bookings-dropdown">
            <Dropdown
              selection
              clearable
              className="bookings-dropdown-min-height"
              placeholder={i18n.t('itemsCategory')}
              options={dropdownOptions}
              onChange={(_, data) => {
                onDropDownChange(data.value);
              }}
            />
          </div>
        </div>
        <div className="bookings-table-container">
          <Table fixed celled selectable padded sortable className="bookings-table">
            {matches === true ? (
              <Table.Header>
                <Table.Row>
                  {matches ? (
                    <>
                      <Table.HeaderCell
                        key="1111"
                        width="1"
                        verticalAlign="middle"
                        textAlign="center"
                      >
                        <Checkbox
                          onChange={() => {
                            if (
                              tableData &&
                              tableData.items &&
                              selectedForDeletion.length !== tableData.items.length
                            ) {
                              setSelectedForDeletion([...Array(tableData.items.length).keys()]);
                            } else {
                              setSelectedForDeletion([]);
                            }
                          }}
                          checked={
                            tableData &&
                            tableData.items &&
                            selectedForDeletion.length === tableData.items.length &&
                            tableData.items.length !== 0
                          }
                          disabled={
                            (tableData && tableData.items && tableData.items.length === 0) ||
                            tableData === null
                          }
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width="2">{i18n.t('itemsPicture')}</Table.HeaderCell>
                    </>
                  ) : (
                    <></>
                  )}
                  <Table.HeaderCell
                    width="7"
                    sorted={
                      tableData
                        ? tableData.selectedColumn === 'title'
                          ? tableData.sortDirection
                          : null
                        : null
                    }
                    onClick={() => handleSort('title')}
                  >
                    {i18n.t('itemsTitle')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      tableData
                        ? tableData.selectedColumn === 'categoryName'
                          ? tableData.sortDirection
                          : null
                        : null
                    }
                    onClick={() => handleSort('categoryName')}
                  >
                    {i18n.t('itemsCategory')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width="2"
                    sorted={
                      tableData
                        ? tableData.selectedColumn === 'price'
                          ? tableData.sortDirection
                          : null
                        : null
                    }
                    onClick={() => handleSort('price')}
                  >
                    {i18n.t('itemsPrice')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            ) : (
              <></>
            )}
            <Table.Body>{tableData ? parseItems(tableData.items) : []}</Table.Body>
          </Table>
        </div>
        <ErrorWarningModal
          open={warningModalOptions.open}
          title={warningModalOptions.title}
          message={warningModalOptions.message}
          ok={warningModalOptions.ok}
          onOk={warningModalOptions.onOk}
          yes={warningModalOptions.yes}
          onYes={warningModalOptions.onYes}
          no={warningModalOptions.no}
          onNo={warningModalOptions.onNo}
        />
        <ErrorModal
          open={errorWarningModalOptions.open}
          title={i18n.t('newPrinterErrorTitle')}
          message={errorWarningModalOptions.message}
          onOk={onErrorWarningModalOk}
        />
        <ItemsScreenModal
          item={modalOptions.item}
          onClose={onCloseModal}
          open={modalOptions.open}
        />
        <ItemsScreenUploadModal open={uploadModalOptions.open} onClose={onCloseModal} />
      </div>
    </>
  );
};
export default ItemsScreen;
