/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import i18n from 'i18next';
import CurrencyInput from 'react-currency-input-field';
import { Modal, Button, Image, TextArea, Dropdown, Form, Radio, Icon } from 'semantic-ui-react';
import useScanDetection from 'use-scan-detection';
import StyledInput from '../../../components/general/Input/Input';
import { getCategories, getAllAllergens } from './ItemsScreenData';
import { useStateValue } from '../../../core/context/StateProvider';
import { asCurrency } from '../../../utils/NumberFormatter';
import './ItemsScreen.css';
import { createItem, deleteItem, updateItem } from '../../../api/ItemAPI';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import AnimatedModal from '../../../components/general/AnimatedModal/AnimatedModal';
import ErrorModal from '../../../components/general/ErrorModal/ErrorModal';

const ItemsScreenModal = (props) => {
  const { item, onClose, open } = props;
  const [{ config, token, shop }] = useStateValue();
  const [dropDownData, setDropDownData] = useState({ categories: [], allergens: [] });
  const [newSpecialSelection, setNewSpecialSelection] = useState({ name: '', price: '' });
  const [newOptionGroup, setNewOptionGroup] = useState({
    title: '',
    options: [],
  });
  const [newIngredient, setNewIngredient] = useState({ name: '' });
  const [image, setImage] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [allergens, setAllergens] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [specialSelections, setSpecialSelections] = useState(null);
  const [optionGroups, setOptionGroups] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [inHouseTaxRate, setInHouseTaxRate] = useState(null);
  const [takeAwayTaxRate, setTakeAwayTaxRate] = useState(null);
  const availabilityOptions = {
    not: 'not',
    all: 'all',
    terminal: 'terminal',
    qr: 'qr',
  };
  const [selectedAvailabilityOption, setSelectedAvailabilityOption] = useState(
    item && item.availability ? item.availability : availabilityOptions.all
  );
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });

  useEffect(() => {
    if (item && selectedAvailabilityOption !== item.availability) {
      setSelectedAvailabilityOption(item.availability);
    }
    if (dropDownData) {
      if (dropDownData.categories.length === 0 && dropDownData.allergens.length === 0) {
        getDropdownData();
      }
    }
    if (dropDownData === null && !refreshRequired) {
      setRefreshRequired(true);
    }
    if (item && inHouseTaxRate === null) {
      setInHouseTaxRate(item.inHouseTaxRate);
    }
    if (item && takeAwayTaxRate === null) {
      setTakeAwayTaxRate(item.takeAwayTaxRate);
    }
    if (open === true && item === null && takeAwayTaxRate === null && inHouseTaxRate === null) {
      setInHouseTaxRate(0.19);
      setTakeAwayTaxRate(0.07);
    }
  }, [item ? item : null]);

  useScanDetection({
    minLength: 8,
    onComplete: (barcode) => {
      if (shop.hasBarcodeScanner === true) {
        setBarcode(barcode);
        setIsSaveDisabled(false);
      }
    },
  });

  const getCategoryTranslation = (category) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (
      category.name !== i18n.t('appAllCategories') &&
      lang &&
      category.translations &&
      category.translations.length > 0
    ) {
      const name = category.translations.find((translation) => translation.lang === lang);
      if (name) {
        return name.val;
      }
      const englishName = category.translations.find((translation) => translation.lang === 'en');
      if (englishName) {
        return englishName.val;
      }
      return category.name;
    }
    return category.name;
  };

  const getCategoryTranslationForName = (categoryName) => {
    const lang = sessionStorage.getItem('language') || navigator.language.substring(0, 2);
    if (dropDownData.categories != null) {
      const category = dropDownData.categories.find((category) => category.name === categoryName);
      if (category && lang && category.translations && category.translations.length > 0) {
        const name = category.translations.find((translation) => translation.lang === lang);
        if (name) {
          return name.val;
        }
        const englishName = category.translations.find((translation) => translation.lang === 'en');
        if (englishName) {
          return englishName.val;
        }
        return category.name;
      }
    }
    return categoryName;
  };

  const getOriginalCategoryFromName = (categoryName) => {
    if (dropDownData.categories !== null) {
      const category = dropDownData.categories.find((category) =>
        category.translations.find((translation) => translation.val === categoryName)
      );
      if (category) {
        return category.name;
      }
      return categoryName;
    }
    return categoryName;
  };

  const getDropdownData = async () => {
    const cat = await getCategories();
    const all = await getAllAllergens();
    if (cat && all && cat.success && all.success) {
      setDropDownData({
        categories: cat.categories,
        allergens: all.allergens,
      });
    } else {
      setDropDownData(null);
    }
  };

  const dropdownOptions = (categories) => {
    if (categories) {
      const options = [];
      const translatedCategories = categories.map((category) => getCategoryTranslation(category));
      translatedCategories.sort();
      translatedCategories.forEach((category) => {
        const object = {};
        object.key = category.key;
        object.value = category;
        object.text = category;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const allergensDropdownOptions = (allergens) => {
    if (allergens) {
      const options = [];
      allergens.forEach((allergen, index) => {
        const object = {};
        object.key = index;
        object.value = allergen;
        object.text = allergen;
        options.push(object);
      });
      return options;
    }
    return [];
  };

  const onDropdownChange = (value) => {
    const category = dropDownData.categories.find(
      (category) => category.name === getOriginalCategoryFromName(value)
    );
    if (item) {
      item.categoryId = category._id;
      item.categoryName = category.name;
    }
    setCategoryId(category._id);
    setIsSaveDisabled(false);
  };

  const onAllergensDropdownChange = (value) => {
    if (item) {
      item.allergens = value;
    }
    setAllergens(value);
    setIsSaveDisabled(false);
  };

  const onFileUpload = (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 500000) {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('itemImageError'),
        });
      } else {
        setIsSaveDisabled(false);
        setImage(URL.createObjectURL(e.target.files[0]));
        const reader = new FileReader();
        reader.onload = (upload) => setImageBase64(upload.target.result);
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
  };

  const onSave = async () => {
    const itemChanges = {};
    if (item) {
      if (title) {
        itemChanges.title = title;
      }
      if (price !== null) {
        itemChanges.price = price;
      }
      if (description) {
        itemChanges.description = description;
      }
      itemChanges.barcode = barcode;
      if (categoryId) {
        itemChanges.categoryId = categoryId;
      }
      if (specialSelections) {
        itemChanges.specialSelections = specialSelections;
      }
      if (allergens) {
        itemChanges.allergens = allergens;
      }
      if (ingredients) {
        itemChanges.ingredients = ingredients;
      }
      if (imageBase64) {
        itemChanges.picture = imageBase64;
      }
      if (selectedAvailabilityOption !== item.availability) {
        itemChanges.availability = selectedAvailabilityOption;
      }
      if (item.inHouseTaxRate !== inHouseTaxRate) {
        itemChanges.inHouseTaxRate = inHouseTaxRate;
      }
      if (optionGroups) {
        if (optionGroups.length > 0) {
          const allOptionGroups = optionGroups.filter(
            (optionGroup) =>
              optionGroup.title !== '' && optionGroup.options && optionGroup.options.length > 0
          );
          itemChanges.optionGroups = allOptionGroups;
        } else {
          itemChanges.optionGroups = [];
        }
      }
      if (item.takeAwayTaxRate !== takeAwayTaxRate) {
        itemChanges.takeAwayTaxRate = takeAwayTaxRate;
      }
      setIsLoading(true);
      const res = await updateItem(token, item._id, itemChanges);
      if (res && res.success) {
        setIsLoading(false);
        setIsSaveDisabled(true);
        closeModal(true);
      } else {
        setErrorWarningModalOptions({
          open: true,
          message: `${i18n.t('itemsUpdateError')} ${res.error}`,
        });
        setIsLoading(false);
        setIsSaveDisabled(true);
      }
    } else {
      const showMissingFieldModal = () => {
        setErrorWarningModalOptions({
          open: true,
          message: i18n.t('itemsMissingFieldsError'),
        });
      };
      let dataIsAlright = true;
      if (!title) {
        dataIsAlright = false;
        showMissingFieldModal();
      } else {
        itemChanges.title = title;
      }
      if (price === null || price === undefined) {
        dataIsAlright = false;
        showMissingFieldModal();
      } else {
        itemChanges.price = price;
      }
      if (!categoryId) {
        dataIsAlright = false;
        showMissingFieldModal();
      } else {
        itemChanges.categoryId = categoryId;
      }
      if (imageBase64) {
        itemChanges.picture = imageBase64;
      }
      if (description) {
        itemChanges.description = description;
      }
      if (barcode) {
        itemChanges.barcode = barcode;
      }
      if (specialSelections) {
        itemChanges.specialSelections = specialSelections;
      }
      if (optionGroups) {
        if (optionGroups.length > 0) {
          const allOptionGroups = optionGroups.filter(
            (optionGroup) =>
              optionGroup.title !== '' && optionGroup.options && optionGroup.options.length > 0
          );
          itemChanges.optionGroups = allOptionGroups;
        } else {
          itemChanges.optionGroups = [];
        }
      }
      if (allergens) {
        itemChanges.allergens = allergens;
      }
      if (ingredients) {
        itemChanges.ingredients = ingredients;
      }
      if (!inHouseTaxRate) {
        dataIsAlright = false;
        showMissingFieldModal();
      } else {
        itemChanges.inHouseTaxRate = inHouseTaxRate;
      }
      if (selectedAvailabilityOption) {
        itemChanges.availability = selectedAvailabilityOption;
      }
      if (!takeAwayTaxRate) {
        dataIsAlright = false;
        showMissingFieldModal();
      } else {
        itemChanges.takeAwayTaxRate = takeAwayTaxRate;
      }
      if (dataIsAlright) {
        itemChanges.shopId = shop._id;
        setIsLoading(true);
        const res = await createItem(token, itemChanges);
        if (res && res.success) {
          setIsLoading(false);
          setIsSaveDisabled(true);
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: `${i18n.t('itemCreateError')} ${res.error}`,
          });
          setIsLoading(false);
          setIsSaveDisabled(true);
        }
      }
    }
  };

  const onAvailabilityChanged = (availabilityOption) => {
    setSelectedAvailabilityOption(availabilityOption);
    setIsSaveDisabled(false);
  };

  const onDelete = async () => {
    setIsDeleteLoading(true);
    setWarningModalOptions({
      open: true,
      title: i18n.t('itemsDeleteWarningTitle'),
      message: i18n.t('itemsDeleteWarningMessage'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        const res = await deleteItem(token, item._id);
        if (res && res.success) {
          closeModal(true);
        } else {
          setErrorWarningModalOptions({
            open: true,
            message: i18n.t('itemDeleteError'),
          });
          setIsDeleteLoading(false);
          setWarningModalOptions({
            open: false,
            title: '',
            message: '',
            ok: false,
            onOk: null,
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
        }
      },
      no: true,
      onNo: () => {
        setIsDeleteLoading(false);
        setWarningModalOptions({
          open: false,
          title: '',
          message: '',
          ok: false,
          onOk: null,
          yes: false,
          onYes: null,
          no: false,
          onNo: null,
        });
      },
    });
  };

  const parseSpecialSelections = () => {
    let specialSelectionsArray = [];
    if (item) {
      specialSelectionsArray = item.specialSelections;
    } else {
      specialSelectionsArray = specialSelections;
    }
    return (
      <>
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          {specialSelectionsArray ? (
            specialSelectionsArray.map((selection, index) => (
              <Fragment key={`${index}frag`}>
                <Form.Group
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  className="items-specialSelections-group"
                >
                  <Form.Input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    width="13"
                    placeholder={i18n.t('itemsTitle')}
                    name={`${selection.name}-${index}`}
                    id={`${selection.name}-${index}`}
                    defaultValue={selection.name}
                    readOnly
                  />
                  <CurrencyInput
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    readOnly
                    className="items-specialSelections-price"
                    placeholder={i18n.t('itemsPrice')}
                    disableGroupSeparators
                    allowNegativeValue={false}
                    id={`${selection.price}-${index}`}
                    name={`${selection.price}-${index}`}
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    defaultValue={`${selection.price / 100}`}
                  />
                  <Form.Button
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    basic
                    color="red"
                    icon="delete"
                    onClick={() => {
                      if (item) {
                        item.specialSelections.splice(index, 1);
                        setSpecialSelections(item.specialSelections);
                      } else {
                        specialSelections.splice(index, 1);
                        setSpecialSelections(specialSelections);
                      }
                      setNewSpecialSelection({ name: '', price: '' });
                      setIsSaveDisabled(false);
                    }}
                  />
                </Form.Group>
              </Fragment>
            ))
          ) : (
            <></>
          )}
          <Form.Group
            className="items-specialSelections-group"
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            <Form.Input
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              width="13"
              placeholder={i18n.t('itemsTitle')}
              name="name"
              value={newSpecialSelection.name}
              onChange={(e) => {
                setNewSpecialSelection({ name: e.target.value, price: newSpecialSelection.price });
              }}
            />
            <CurrencyInput
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              className="items-specialSelections-price"
              placeholder={i18n.t('itemsPrice')}
              name="price"
              disableGroupSeparators
              allowNegativeValue={false}
              intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
              onValueChange={(value) => {
                setNewSpecialSelection({ name: newSpecialSelection.name, price: value });
              }}
              value={newSpecialSelection.price}
            />
            <Form.Button
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              color="green"
              basic
              icon="add"
              disabled={!(newSpecialSelection.name && newSpecialSelection.price)}
              onClick={() => {
                let { price } = newSpecialSelection;
                if (price.includes(',')) {
                  price = price.replace(',', '.');
                }
                if (item) {
                  item.specialSelections.push({
                    name: newSpecialSelection.name,
                    price: parseFloat(price) * 100,
                  });
                  setSpecialSelections(item.specialSelections);
                } else if (specialSelections) {
                  specialSelections.push({
                    name: newSpecialSelection.name,
                    price: parseFloat(price) * 100,
                  });
                  setSpecialSelections(specialSelections);
                } else {
                  const specialSelectionsArrayTmp = [];
                  specialSelectionsArrayTmp.push({
                    name: newSpecialSelection.name,
                    price: parseFloat(price) * 100,
                  });
                  setSpecialSelections(specialSelectionsArrayTmp);
                }
                setIsSaveDisabled(false);
                setNewSpecialSelection({ name: '', price: '' });
              }}
            />
          </Form.Group>
        </Form>
      </>
    );
  };

  const [newOption, setNewOption] = useState([]);
  const parseOptionGroups = () => {
    let optionGroupsArray = [];
    if (item) {
      optionGroupsArray = item.optionGroups;
    } else {
      optionGroupsArray = optionGroups ? optionGroups : [];
    }
    return (
      <>
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          {optionGroupsArray ? (
            optionGroupsArray.map((optionGroup, index) => (
              <Fragment key={`og-${index}`}>
                <Form.Group
                  className="items-specialSelections-group"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                >
                  <Form.Input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    width="16"
                    placeholder={i18n.t('groupName')}
                    name="title"
                    value={optionGroup.title}
                    readOnly
                  />
                  <Form.Button
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    color={optionGroup.title !== '' ? 'red' : 'green'}
                    basic
                    icon={optionGroup.title !== '' ? 'delete' : 'add'}
                    disabled={optionGroup.length === 0}
                    onClick={() => {
                      optionGroupsArray.splice(index, 1);
                      setOptionGroups([...optionGroupsArray]);
                      setIsSaveDisabled(false);
                      setNewOption([]);
                      setNewOptionGroup({ title: '', options: [] });
                    }}
                  />
                </Form.Group>
                {optionGroup.options ? (
                  optionGroup.options.map((option, idx) => (
                    <Fragment key={`itm-mdl-ognew-${index}-${idx}`}>
                      <Form.Group
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        className="items-specialSelections-group"
                        style={{
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          paddingLeft: 10,
                        }}
                      >
                        <Icon
                          name="dot circle"
                          fitted
                          style={{ color: '#7268cd', paddingBottom: 18 }}
                        />
                        <Form.Input
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') e.preventDefault();
                          }}
                          width="12"
                          placeholder="Name"
                          name="title"
                          value={option.name}
                          readOnly
                          onChange={(e) => {
                            const oGroup = optionGroup;
                            oGroup.options[idx].name = e.target.value;
                            const oGroups = optionGroupsArray;
                            oGroups[index] = oGroup;
                            setOptionGroups([...oGroups]);
                          }}
                        />
                        <CurrencyInput
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') e.preventDefault();
                          }}
                          className="items-specialSelections-price"
                          placeholder={i18n.t('itemsPrice')}
                          name="price"
                          disableGroupSeparators
                          allowNegativeValue={false}
                          intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                          readOnly
                          onValueChange={(value) => {
                            const oGroup = optionGroup;
                            oGroup.options[idx].price = value;
                            const oGroups = optionGroupsArray;
                            oGroups[index] = oGroup;
                            setOptionGroups([...oGroups]);
                          }}
                          value={`${option.price / 100}`}
                        />
                        <Form.Button
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') e.preventDefault();
                          }}
                          color="red"
                          basic
                          icon="delete"
                          onClick={() => {
                            const oGroup = optionGroup;
                            oGroup.options.splice(idx, 1);
                            const oGroups = optionGroupsArray;
                            oGroups[index] = oGroup;
                            setOptionGroups([...oGroups]);
                            setIsSaveDisabled(false);
                            setNewOption([]);
                            setNewOptionGroup({ title: '', options: [] });
                          }}
                        />
                      </Form.Group>
                    </Fragment>
                  ))
                ) : (
                  <></>
                )}
                <Form.Group
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  className="items-specialSelections-group"
                  style={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingLeft: 10,
                  }}
                >
                  <Icon name="dot circle" fitted style={{ color: '#7268cd', paddingBottom: 18 }} />
                  <Form.Input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    width="12"
                    placeholder="Name"
                    name="title"
                    value={newOption[index] ? newOption[index].name : ''}
                    onChange={(e) => {
                      newOption[index] = {
                        name: e.target.value,
                        price: newOption[index] ? newOption[index].price : '',
                      };
                      setNewOption([...newOption]);
                    }}
                  />
                  <CurrencyInput
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    className="items-specialSelections-price"
                    placeholder={i18n.t('itemsPrice')}
                    name="price"
                    disableGroupSeparators
                    allowNegativeValue={false}
                    intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                    onValueChange={(value) => {
                      newOption[index] = {
                        name: newOption[index] ? newOption[index].name : '',
                        price: value,
                      };
                      setNewOption([...newOption]);
                    }}
                    value={newOption[index] ? newOption[index].price : ''}
                  />
                  <Form.Button
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    color="green"
                    basic
                    icon="add"
                    disabled={
                      !(
                        newOption[index] &&
                        newOption[index].name &&
                        newOption[index] &&
                        newOption[index].price
                      )
                    }
                    onClick={() => {
                      let { price } = newOption[index];
                      if (price.includes(',')) {
                        price = price.replace(',', '.');
                      }
                      const tmpNewOption = {
                        name: newOption[index].name,
                        price: price * 100,
                      };
                      optionGroupsArray[index].options.push(tmpNewOption);
                      setOptionGroups([...optionGroupsArray]);
                      newOption.splice(index, 1);
                      setNewOption([...newOption]);
                      setIsSaveDisabled(false);
                    }}
                  />
                </Form.Group>
              </Fragment>
            ))
          ) : (
            <></>
          )}
          <Form.Group
            className="items-specialSelections-group"
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            <Form.Input
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              width="16"
              placeholder={i18n.t('groupName')}
              name="title"
              value={newOptionGroup.title}
              onChange={(e) => {
                if (e.target.value === '') {
                  setNewOptionGroup({ title: '', options: [] });
                } else {
                  setNewOptionGroup({ title: e.target.value, options: [] });
                }
              }}
            />
            <Form.Button
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              color="green"
              basic
              icon="add"
              disabled={newOptionGroup.title.length === 0}
              onClick={() => {
                if (
                  optionGroupsArray.find(
                    (optionGroup) => optionGroup.title === newOptionGroup.title
                  )
                ) {
                  setErrorWarningModalOptions({
                    open: true,
                    message: i18n.t('optionGroupError1'),
                  });
                } else {
                  if (optionGroups) {
                    setOptionGroups([...optionGroups, newOptionGroup]);
                  } else {
                    setOptionGroups([newOptionGroup]);
                  }
                  if (item) {
                    if (item.optionGroups) {
                      item.optionGroups.push(newOptionGroup);
                    } else {
                      item.optionGroups = [newOptionGroup];
                    }
                  }
                  setNewOptionGroup({ title: '', options: [] });
                }
              }}
            />
          </Form.Group>
        </Form>
      </>
    );
  };

  const parseIngredients = () => {
    let ingredientsArray = [];
    if (item) {
      ingredientsArray = item.ingredients;
    } else {
      ingredientsArray = ingredients;
    }
    return (
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        {ingredientsArray ? (
          ingredientsArray.map((ingredient, index) => (
            <Fragment key={`itm-mdl-ingr-${index}`}>
              <Form.Group
                className="items-allergens-group"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Form.Input
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  width="16"
                  placeholder={i18n.t('itemsIngredient')}
                  name={`${ingredient}-${index}`}
                  id={`${ingredient}-${index}`}
                  defaultValue={ingredient}
                  readOnly
                />
                <Form.Button
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  icon="delete"
                  basic
                  color="red"
                  onClick={() => {
                    if (item) {
                      item.ingredients.splice(index, 1);
                      setIngredients(item.ingredients);
                    } else {
                      ingredients.splice(index, 1);
                      setIngredients(ingredients);
                    }
                    setNewIngredient({ name: '' });
                    setIsSaveDisabled(false);
                  }}
                  id={`delete${ingredient}-${index}`}
                  type="button"
                />
              </Form.Group>
            </Fragment>
          ))
        ) : (
          <></>
        )}
        <Form.Group
          className="items-allergens-group"
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          <Form.Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            width="16"
            placeholder={i18n.t('itemsIngredient')}
            name="name"
            value={newIngredient.name}
            onChange={(e) => {
              setNewIngredient({ name: e.target.value });
            }}
          />
          <Form.Button
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            icon="add"
            basic
            color="green"
            id="newIngredient"
            onClick={() => {
              if (item) {
                item.ingredients.push(newIngredient.name);
                setIngredients(item.ingredients);
              } else if (ingredients) {
                ingredients.push(newIngredient.name);
                setIngredients(ingredients);
              } else {
                const ingredientsArrayTmp = [];
                ingredientsArrayTmp.push(newIngredient.name);
                setIngredients(ingredientsArrayTmp);
              }
              setNewIngredient({ name: '' });
              setIsSaveDisabled(false);
            }}
            type="button"
            disabled={newIngredient.name.length === 0}
          />
        </Form.Group>
      </Form>
    );
  };

  const closeModal = (refreshNeeded) => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
    setErrorWarningModalOptions({ open: false, message: '' });
    setIsDeleteLoading(false);
    setTitle(null);
    setPrice(null);
    setDescription(null);
    setAllergens(null);
    setSpecialSelections(null);
    setOptionGroups(null);
    setIngredients(null);
    setCategoryId(null);
    setImage(null);
    setImageBase64(null);
    setIsLoading(false);
    setInHouseTaxRate(null);
    setTakeAwayTaxRate(null);
    setBarcode(null);
    setSelectedAvailabilityOption(availabilityOptions.all);
    setNewSpecialSelection({ name: '', price: '' });
    setNewOptionGroup({ title: '', options: [] });
    setNewIngredient({ name: '' });
    onClose(refreshNeeded);
  };

  return (
    <>
      <AnimatedModal open={open} dimmer="blurring" size="large" closeOnEscape closeOnDimmerClick>
        <Modal.Header style={{ wordBreak: 'break-all', borderBottom: 'none' }}>
          {title || (item ? item.title : null) || i18n.t('itemsNewItemTitle')}
          <Button
            icon="cancel"
            color="black"
            basic
            onClick={() => closeModal(false)}
            floated="right"
            className="items-close-btn"
            circular
          />
        </Modal.Header>
        <Modal.Content scrolling image>
          <label htmlFor="file" style={{ display: 'table', margin: '0 auto' }}>
            <Image
              src={image || (item ? `${config.cloudinaryUrlPrefix}${item.picture}` : null)}
              rounded
              size="medium"
              wrapped
              className="items-modal-image"
              id="items-modal-image"
              htmlFor="file"
              style={{ border: '1px solid #7268cd' }}
            />
            <input type="file" id="file" onChange={onFileUpload} accept="image/*" />
          </label>
          <div className="items-modal-content">
            <div className="items-modal-header">
              <StyledInput
                id="title"
                title={item ? item.title : null}
                placeholder={i18n.t('itemsTitle')}
                onChangeTitle={(value) => {
                  setTitle(value);
                  setIsSaveDisabled(false);
                }}
              />
              <StyledInput
                id="price"
                title={item ? `${asCurrency(item.price / 100)} €` : null}
                placeholder={i18n.t('itemsPrice')}
                type="currency"
                onChangePrice={(value) => {
                  if (value) {
                    setPrice(parseFloat(value.replace(',', '.')) * 100);
                    setIsSaveDisabled(false);
                  } else {
                    setPrice(null);
                  }
                }}
              />
            </div>
            <TextArea
              placeholder={i18n.t('itemsDescription')}
              className="items-modal-textarea"
              defaultValue={item ? (item.description ? item.description : '') : description}
              onChange={(e) => {
                if (item) {
                  item.description = e.target.value;
                }
                setDescription(e.target.value);
                setIsSaveDisabled(false);
              }}
            />
            <p style={{ fontWeight: 'bold' }}>{i18n.t('itemsCategory')}</p>
            <Dropdown
              selection
              placeholder={i18n.t('itemsCategories')}
              fluid
              defaultValue={
                item
                  ? item.categoryName
                    ? getCategoryTranslationForName(item.categoryName)
                    : ''
                  : null
              }
              onChange={(_, data) => onDropdownChange(data.value)}
              options={dropdownOptions(
                dropDownData ? (dropDownData.categories ? dropDownData.categories : null) : null
              )}
              className="items-modal-dropdown"
              id="items-dropdown"
            />
            <p style={{ fontWeight: 'bold' }}>{i18n.t('itemsSpecialSelections')}</p>
            {parseSpecialSelections()}
            <p style={{ fontWeight: 'bold' }}>{i18n.t('optionGroups')}</p>
            {parseOptionGroups()}
            <p style={{ fontWeight: 'bold' }}>{i18n.t('itemsAllergens')}</p>
            <Dropdown
              selection
              placeholder={i18n.t('itemsAllergens')}
              fluid
              multiple
              defaultValue={item ? item.allergens : null}
              options={allergensDropdownOptions(
                dropDownData ? (dropDownData.allergens ? dropDownData.allergens : null) : null
              )}
              onChange={(_, data) => onAllergensDropdownChange(data.value)}
              className="items-modal-dropdown"
              id="items-dropdown"
            />
            <p style={{ fontWeight: 'bold' }}>{i18n.t('itemsIngredients')}</p>
            {parseIngredients()}
            {shop && shop.hasBarcodeScanner && (
              <>
                <p style={{ fontWeight: 'bold' }}>Barcode</p>
                <TextArea
                  rows={1}
                  placeholder="Barcode"
                  className="items-modal-textarea-barcode"
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  defaultValue={item ? (item.barcode ? item.barcode : barcode) : barcode}
                  onChange={(e) => {
                    if (item) {
                      item.barcode = e.target.value.trim();
                    }
                    setBarcode(e.target.value.trim());
                    setIsSaveDisabled(false);
                  }}
                />
              </>
            )}
            <p style={{ fontWeight: 'bold' }}>{`In-House ${i18n.t('taxRate')}`}</p>
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            >
              <Form.Field
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Radio
                  label="19%"
                  name="radioGroup"
                  value={0.19}
                  checked={inHouseTaxRate === 0.19}
                  onChange={() => {
                    setInHouseTaxRate(0.19);
                    if (item) setIsSaveDisabled(false);
                  }}
                />
              </Form.Field>
              <Form.Field
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Radio
                  label="7%"
                  name="radioGroup"
                  value={0.07}
                  checked={inHouseTaxRate === 0.07}
                  onChange={() => {
                    setInHouseTaxRate(0.07);
                    if (item) setIsSaveDisabled(false);
                  }}
                />
              </Form.Field>
            </Form>
            <p style={{ fontWeight: 'bold' }}>{`Take-Away ${i18n.t('taxRate')}`}</p>
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            >
              <Form.Field
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Radio
                  label="19%"
                  name="radioGroup"
                  value={0.19}
                  checked={takeAwayTaxRate === 0.19}
                  onChange={() => {
                    setTakeAwayTaxRate(0.19);
                    if (item) setIsSaveDisabled(false);
                  }}
                />
              </Form.Field>
              <Form.Field
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              >
                <Radio
                  label="7%"
                  name="radioGroup"
                  value={0.07}
                  checked={takeAwayTaxRate === 0.07}
                  onChange={() => {
                    setTakeAwayTaxRate(0.07);
                    if (item) setIsSaveDisabled(false);
                  }}
                />
              </Form.Field>
            </Form>
            <br />
          </div>
        </Modal.Content>
        <Modal.Actions
          style={{
            borderTop: 'none',
            backgroundColor: 'white',
          }}
        >
          {item ? (
            <Button
              loading={isDeleteLoading}
              content={i18n.t('itemsDelete')}
              floated="left"
              onClick={() => onDelete()}
              negative
            />
          ) : (
            <></>
          )}
          <Button
            loading={isLoading}
            content={i18n.t('itemsSave')}
            disabled={isSaveDisabled}
            floated="right"
            onClick={onSave}
            color="teal"
          />
          <Button.Group
            style={{
              border: `1px solid ${
                selectedAvailabilityOption === availabilityOptions.not
                  ? 'red'
                  : selectedAvailabilityOption === availabilityOptions.all
                  ? 'mediumseagreen'
                  : '#7268cd'
              }`,
              borderRadius: 8,
              overflow: 'hidden',
              height: 36,
              marginLeft: 8,
            }}
            floated="left"
          >
            <Button
              basic={selectedAvailabilityOption !== availabilityOptions.not}
              color={selectedAvailabilityOption !== availabilityOptions.not ? 'white' : 'red'}
              style={{
                borderRadiusLeft: 8,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
              onClick={() => onAvailabilityChanged(availabilityOptions.not)}
            >
              {i18n.t('itemsUnavailable')}
            </Button>
            <Button
              basic={selectedAvailabilityOption !== availabilityOptions.all}
              color={selectedAvailabilityOption !== availabilityOptions.all ? 'white' : 'green'}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
              onClick={() => onAvailabilityChanged(availabilityOptions.all)}
            >
              {i18n.t('itemsAvailable')}
            </Button>
            <Button
              basic={selectedAvailabilityOption !== availabilityOptions.terminal}
              color={selectedAvailabilityOption !== availabilityOptions.terminal ? 'white' : 'teal'}
              style={{
                borderRadiusRight: 8,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
              onClick={() => onAvailabilityChanged(availabilityOptions.terminal)}
            >
              Terminal
            </Button>
            <Button
              basic={selectedAvailabilityOption !== availabilityOptions.qr}
              color={selectedAvailabilityOption !== availabilityOptions.qr ? 'white' : 'teal'}
              style={{
                borderRadiusRight: 8,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 8,
              }}
              onClick={() => onAvailabilityChanged(availabilityOptions.qr)}
            >
              QR
            </Button>
          </Button.Group>

          <Button floated="left" color="teal">
            <input type="file" id="file" onChange={onFileUpload} accept="image/*" />
            <label htmlFor="file">{i18n.t('changePic')}</label>
          </Button>
        </Modal.Actions>
      </AnimatedModal>
      <ErrorModal
        open={errorWarningModalOptions.open}
        title={i18n.t('newPrinterErrorTitle')}
        message={errorWarningModalOptions.message}
        onOk={onErrorWarningModalOk}
      />
      <ErrorWarningModal
        open={warningModalOptions.open}
        title={warningModalOptions.title}
        message={warningModalOptions.message}
        ok={warningModalOptions.ok}
        onOk={warningModalOptions.onOk}
        yes={warningModalOptions.yes}
        onYes={warningModalOptions.onYes}
        no={warningModalOptions.no}
        onNo={warningModalOptions.onNo}
      />
    </>
  );
};

export default ItemsScreenModal;
